<template>
  <div ref="container">
    <b-table class="slottable table-striped  borderless queuetable-monitor" id="slotOverview" show-empty :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :hover="hover"  :filter="filter" :filter-included-fields="filterOn">
      <template slot="top-row" slot-scope="{ fields }">
              <td colspan="7" class="table-search">
              <b-form-input id="filter-input" v-model="filter" type="search" v-bind:placeholder="typeToSearch()" > </b-form-input>
              <b-button  @click="filter = '';sortBy = 'start';sortDesc = true" id="clearFilters" style="display:none;"></b-button>
              </td>
      </template>
      <template v-slot:cell(visit_slot)="data">
        <span v-html="slot(data.value, data.item.multiple_entry)"></span>
      </template>
      <template v-slot:cell(building)="data">
        <span v-html="building(data.value)"></span>
      </template>
      <template v-slot:cell(cargo_exchanges)="data">
        <span v-html="awbstatus(data.value)"></span>
      </template>
      <template v-slot:cell(exchange_types)="data">
        <span v-html="typedescription(data.value)"></span>
      </template>
      <template v-slot:cell(PersonName)="data">
        <span v-html="PersonName(data.value)"></span>
      </template>
      <template v-slot:cell(CompName)="data">
        <span>{{ data.value.toUpperCase() }}</span>
      </template>
      <template v-slot:cell(transport_id)="data">
        <div :class="{priotruck:data.item.visit_type==2266,priotruckconfirmed:data.item.slot_confirm==1}"><i v-show="data.item.visit_type==2266" class="fas fa-shipping-fast" style="padding-top:7px;"/><span><span v-html="license(data.value)"></span></span></div>
      </template>
      <template v-slot:empty="scope">
        <h4><center><span class="glyphicon glyphicon-leaf" aria-hidden="true" style="font-size: 60px;color:#3f3f3f;"></span><br/>{{noQueue()}}</center></h4>
      </template>
    </b-table>
  </div>
</template>

<script>

  var preplannedVisitsList = [];
  var fields = [];
  export default {
    data() {
      return {
        fields: [
          {key: 'visit_slot',label: 'Slot'},
          {key: 'building',label: 'Building'},
          {key: 'CompName',label: 'Company'},
          {key: 'PersonName',label: 'Name'},
          {key: 'transport_id',label: 'License plate'},
          {key: 'exchange_types',label: 'Type'},
          {key: 'cargo_exchanges',label: 'AWB / Flight number'}
            ],
         items: preplannedVisitsList,
         interval: null,
         hover: false,
         sortBy: 'expected',
         sortDesc: false,
         filter: null,
         searchResults: [],
         filterOn: ['PersonName','CompName','transport_id','cargo_exchanges', 'building', 'exchange_types']
        }
    },
    beforeCreate() {
      window.parameter = this.$attrs;
      self.items = preplannedVisits;
    },
    created() {
      var self = this;
      self.items = preplannedVisits;
    },
    mounted() {
    },
    methods: {
        license(value) {
          if(value) {
            var licenseHTML = '<span class="scontainer" style="text-transform: uppercase;font-size:0.8em;">&nbsp;' + value + '&nbsp;</span>';
            return licenseHTML;
          };
        },
        slot(value, multiple_entry) {
          const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
          var slots = JSON.parse(value);
          var minslot = slots[0].id;
          var maxslot = slots[slots.length - 1].id;
          if(minslot && maxslot) {
            var slotstart=new Date((minslot * 1000));
            var slotDate = slotstart.getDate() + '-' + (slotstart.getMonth() + 1) + '-' + slotstart.getFullYear();
            var slotstartvalue=(slotstart.getHours()<10?'0':'') + slotstart.getHours() + ':' + (slotstart.getMinutes()<10?'0':'') + slotstart.getMinutes();
            var slotend= new Date(((maxslot * 1000) + 900000));
            var slotendvalue=(slotend.getHours()<10?'0':'') + slotend.getHours() + ':' + (slotend.getMinutes()<10?'0':'') + slotend.getMinutes();
            var slotHTML = '<span class="scontainer" style="white-space: nowrap;background:none;">' + weekday[slotstart.getDay()] + '</span><br/>'
            if(multiple_entry ) {
                slotHTML += '<span class="scontainer" style="white-space: nowrap;background:none;font-weight: 500 !important;">Repeated every week</span><br/>'
            } else {
                slotHTML += '<span class="scontainer" style="white-space: nowrap;background:none;">' + slotDate + '</span><br/>'
            }

            slotHTML += '<span class="scontainer" style="white-space: nowrap;">' + slotstartvalue + ' - ' + slotendvalue + '</span>';
          } else {
            var slotHTML = '<span style="font-style: italic;">No prebooked slots for this location</span>';
          };
          return slotHTML;
        },
        awbstatus(value) {
          if(value) {
              var i;
              var awbHTML = '';
              var numberHTML = '';
              var weightHTML = '';
              var waybill_ident = '';
              var waybillDetails = JSON.parse(value);
              for (i = 0; i < waybillDetails.length; i++) {

                        if(waybillDetails[i].goods_no_item && waybillDetails[i].goods_no_item > 0) {
                          var numberHTML = '<span class="scontainer" style="display: inline-block;padding: 0px;font-weight: 400;text-align: left;padding-left:5px;padding-right:5px;">#' + waybillDetails[i].goods_no_item + '</span>';
                        };

                        if(waybillDetails[i].goods_weight && waybillDetails[i].goods_weight > 0) {
                          if(Number(waybillDetails[i].goods_weight) < 1) { var weightVal = waybillDetails[i].goods_weight} else {var weightVal = Math.round(waybillDetails[i].goods_weight)}
                          var weightHTML = '<span class="scontainer" style="display: inline-block;padding: 0.2px;font-weight: 400;padding-left: 5px;padding-right: 5px;">' + weightVal + 'kg</span>';
                        };

                        if(waybillDetails[i].exchange_type == 2206) {
                          waybill_ident = waybillDetails[i].carrier_ref_flight_number;
                        } else {
                          waybill_ident = waybillDetails[i].waybill_ident;
                        };

                      
                          if(waybillDetails[i].exchange_status == 2151) {          // assigned to Visit
                            awbHTML += '<span class="waybilldetails" style="overflow: hidden;white-space: nowrap;display:block;"><span class="scontainer" style="min-width:120px;display:inline-block;padding:0px;font-weight:400;padding-left: 3px;padding-right: 3px;">' + waybill_ident + '</span>' + numberHTML + weightHTML + '</span>';
                          } else if (waybillDetails[i].exchange_status == 2152) {  // cargo exchange with confirmation
                            awbHTML += '<span class="waybilldetails"  style="overflow: hidden;white-space: nowrap;display:block;"><span class="scontainer" style="min-width:120px;display:inline-block;padding:0px;background-color:#6bb18c;color:#ffffff;font-weight:400;padding-left: 3px;padding-right: 3px;">' + waybill_ident + '</span>' + numberHTML + weightHTML + '</span>';
                          } else if (waybillDetails[i].exchange_status == 2257) {  // approved  for exchange
                            awbHTML += '<span class="waybilldetails"  style="overflow: hidden;white-space: nowrap;display:block;"><span class="scontainer" style="min-width:120px;display:inline-block;padding:0px;background-color:#5f94b9;color:#ffffff;font-weight:400;padding-left: 3px;padding-right: 3px;">' + waybill_ident + '</span>' + numberHTML + weightHTML + '</span>';
                          } else if (waybillDetails[i].exchange_status == 2265) {  // not assigned
                            awbHTML += '<span class="waybilldetails"  style="overflow: hidden;white-space: nowrap;display:block;"><span class="scontainer" style="min-width:120px;display:inline-block;padding:0px;font-weight:400;padding-left: 3px;padding-right: 3px;">' + waybill_ident + '</span>' + numberHTML + weightHTML + '</span>';
                          } else if (waybillDetails[i].exchange_status == 2275) {  // cargo exchange ready
                            awbHTML += '<span class="waybilldetails"  style="overflow: hidden;white-space: nowrap;display:block;"><span class="scontainer" style="min-width:120px;display:inline-block;padding:0px;font-weight:400;padding-left: 3px;padding-right: 3px;">' + waybill_ident + '</span>' + numberHTML + weightHTML + '</span>';
                          } else if (waybillDetails[i].exchange_status == 2312) {  // cancelled
                            awbHTML += '<span class="waybilldetails"  style="overflow: hidden;white-space: nowrap;display:block;"><span class="scontainer" style="min-width:120px;display:inline-block;padding:0px;text-decoration: line-through;font-weight:400;padding-left: 3px;padding-right: 3px;">' + waybill_ident + '</span>' + numberHTML + weightHTML + '</span>';
                          } else {
                            awbHTML += '<span class="waybilldetails"  style="overflow: hidden;white-space: nowrap;display:block;"><span class="scontainer" style="min-width:120px;display:inline-block;padding:0px;background-color:#ffffff;font-weight:400;padding-left: 3px;padding-right: 3px;">' + waybill_ident + '</span>' + numberHTML + weightHTML + '</span>';
                          }
              };
              if(waybill_ident == 'UNKNOWN' || waybill_ident == null) {
                return ''; 
              } else {
                return awbHTML;  
              }

     
          };


        },
        typedescription(value) {
          var typeHTML = '';
          if(value != '[null]') {
          var types = JSON.parse(value);
          types.forEach((value, index) => {
                  typeHTML += '<span class="scontainer" style="text-transform: uppercase;">&nbsp;' + value + '&nbsp;</span><br>';
              });

          };

          return typeHTML;
        },
        building(buildings) {
          var uniqueBuildings = JSON.parse(buildings).filter((value, index, array) => array.indexOf(value) === index);
          return '<span>' + uniqueBuildings + '</span>'
        },
        PersonName(value) {
          if(value == ',  ') {
            return '';
          } else {
            return '<span>' + value.toUpperCase() + '</span>';
          }

        },
        noQueue () {
          return 'No prebooked slots for this location';
        },
        filteredOut () {
          return 'No results';
        },
        typeToSearch () {
          return 'Type to search';
        },
        sortCompare(a, b, key) {
          var no = 9999999999
          if(!a[key]) {var a = no;} else {var a = a[key];}
          if(!b[key]) {var b = no;} else {var b = b[key];}
          return a - b;
        }
    }
  }

</script>
<style>
 .slottable > thead > tr > th,  .slottable > thead > tr > td{
    border: 0 !important;
    padding: 2rem !important;
    vertical-align: middle !important;
    font-size: 1.6rem;
}
 .slottable > tbody > tr > th,  .slottable > tbody > tr > td,  .slottable > tfoot > tr > th,  .slottable > tfoot > tr > td {
    border: 0 !important;
    font-weight: 300 !important;
    padding: 2rem !important;
    vertical-align:middle !important;
    font-size: 1.6rem;
    line-height: 2.2rem;
}

 .slottable tbody tr {
  cursor: default;
}

 .table-striped > tbody > tr:nth-of-type(2n+1) {
    background-color: #f9f9f9 !important;
}

 .table-striped > tbody > tr:nth-of-type(2n) {
    background-color: #ffffff !important;
}

 .slottable {
    cursor: default;
}

 .slottable {
  margin:0px;
  margin-bottom:0px !important;
}

 .slottable th{
    background: white;
    position: sticky;
    top: 0;
    z-index: 10;
}

.slottable .scontainer{
    overflow: hidden;
    background-color: #eee;
    text-align: center;
    cursor: default;
    margin:2px;
    padding: 0.3rem;
    font-size:1.4rem !important;

}

.waybilldetails .scontainer {
  font-size:1.1rem !important;
  margin-top: -2px;
  margin-bottom: -2px;
}

 input[type="search"] {
  border-radius:5px;
}

</style>
