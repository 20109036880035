<template #content>
  <div>
    <div v-if="step === 2">
      <p class="text-medium-gray">{{(this.translation["3138"])}}</p><br/>
    </div>
    <div v-if="loading == true">
      <div class="spinner" style="margin-top:100px;"></div>
    </div>
    <div v-if="showForm == false">
      <div v-if="this.server_success" id="first_time_success" class="alert alert-success alert-register" style="margin-top:10px;">{{(this.translation["3028"])}}</div>
    </div>
    <div v-if="showForm == true">
      <div v-if="this.errors == true" class="alert alert-danger alert-register">
        {{(this.translation["3143"])}}
      </div>
      <div v-if="this.server_error" id="first_time_error" class="alert alert-danger alert-register"><span>{{this.errorMessage}}</span><span style="display:block;">Please contact the FreightStation Helpdesk</span></div>
      <form>
        <!-- Step 1 -->
        <Transition name="step">
          <div v-if="step === 1">
            <div>
             
            </div>
          </div>
        </Transition>
        <!-- end Step 1 -->
        <!-- Step 2 -->
        <Transition name="step">
          <div v-if="step === 2">
            <div class="form-group row">
                <div class="col-lg-2">
                  <label>
                    {{(this.translation["3001"])}}*
                  </label>
                </div>
                <div class="col-lg-10">
                  <input v-model="companyName" name="companyName" :placeholder='(this.translation["3001"])' class="form-control" required />
                </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2">
                <label>
                  {{(this.translation["3002"])}}*
                </label>
              </div>
              <div class="col-lg-10">
                <input v-model="companyAddress" name="companyAddress" :placeholder='(this.translation["3002"])' class="form-control" required />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3003"])}}*</label></div>
              <div class="col-lg-4">
                <input v-model="companyZipcode" name="companyZipcode" :placeholder='(this.translation["3003"])' class="form-control" required />
              </div>
              <div class="col-lg-2"><label>{{(this.translation["3133"])}}*</label></div>
              <div class="col-lg-4">
                <input v-model="companyCity" name="companyCity" :placeholder='(this.translation["3133"])' class="form-control" required />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3005"])}}*</label></div>
              <div class="col-lg-4">
                <select name="companyCountry" class="form-control" @change="countryChange($event, $event.target.selectedIndex)" v-model="companyCountry" required>
                  <option value="" selected="selected">{{(this.translation["3142"])}}</option>
                  <option v-for="(item, key, index) in countriesTimezonesSorted" :key="index" :value="item.country_code">
                    {{item.name}}
                  </option>
                </select>
              </div>
              <div class="col-lg-2" v-if="companyCountry == 'US'"><label>{{(this.translation["3004"])}}*</label></div>
              <div class="col-lg-4" v-if="companyCountry == 'US'">
                <select v-model="companyState" name="companyState" class="form-control" @change="stateChange($event, $event.target.selectedIndex)" required>
                  <option value="" selected="selected">{{(this.translation["3142"])}}</option>
                  <option v-for="(item, key, index) in usStates" :key="index" :value="item.abbreviation">
                    {{item.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row" v-if="countryTimezones.length > 1">
            <div class="col-lg-2"><label>{{(this.translation["3144"])}}</label></div>
              <div class="col-lg-6">
                <select name="companyCountry" class="form-control" v-model="timezone" required>
                  <option v-for="(item, key, index) in countryTimezones" :key="index" :value="item">
                    {{item.replace('_', ' ')}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3006"])}}*</label></div>
              <div class="col-lg-6"><vue-tel-input v-model="companyPhone" @validate="companyPhoneVal" id="companyPhone" ref="companyPhone" name='companyPhone' :autoFormat=true mode="international" :dropdownOptions ="{showDialCodeInList:true, showSearchBox:true, showFlags:true}" :preferredCountries=preferredCountries :validCharactersOnly=true :required=true></vue-tel-input></div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3007"])}}</label></div>
              <div class="col-lg-6"><input v-model="companyWebsite" name="companyWebsite" :placeholder='(this.translation["3007"])' class="form-control" /></div>
            </div>
          </div>
        </Transition>
        <!-- end Step 2 -->

        <!-- Step 3 -->
        <Transition name="step">
          <div v-if="step === 3">
            <div class="form-group row">
              <div class="col-lg-6"><label>{{(this.translation["3008"])}}*</label></div>
              <div class="col-lg-6"><input v-model="companyGovRegNumber" name="companyGovRegNumber" :placeholder='(this.translation["3008"])' class="form-control" required /></div>
            </div>
            <div class="form-group row">
              <div class="col-lg-6"><label>{{(this.translation["3009"])}}*</label></div>
              <div class="col-lg-6"><input v-model="companyVat" name="companyVat" :placeholder='(this.translation["3009"])' class="form-control" required /></div>
            </div>
            <div class="form-group row" v-if="companyCountry != 'US'">
              <div class="col-lg-6"><label>{{(this.translation["3010"])}}</label></div>
              <div class="col-lg-6"><input v-model="companyRaNumber" name="companyRaNumber" :placeholder='(this.translation["3010"])' class="form-control" /></div>
            </div>
          </div>
        </Transition>
        <!-- end Step 3 -->

        <!-- Step 4 -->
        <Transition name="step">
          <div v-if="step === 4">
            <h4 class="modal-title" id="exampleModalLabel">{{(this.translation["3011"])}}</h4>
            <br/>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3012"])}}*</label></div>
              <div class="col-lg-4"><input v-model="companyAdminName" name="companyAdminName" :placeholder='(this.translation["3012"])' class="form-control" required/></div>
              <div class="col-lg-2"><label>{{(this.translation["3013"])}}*</label></div>
              <div class="col-lg-4"><input v-model="companyAdminSurname" name="companyAdminSurname" :placeholder='(this.translation["3013"])' class="form-control" required/></div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3014"])}}*</label></div>
              <div class="col-lg-4"><input v-model="companyJobTitle" name="companyJobTitle" :placeholder='(this.translation["3014"])' class="form-control" required/></div>
              <div class="col-lg-2"><label>{{(this.translation["3015"])}}</label></div>
              <div class="col-lg-4">
                <select name="companyGender" class="form-control" v-model="companyGender">
                  <option value="" selected="selected">{{(this.translation["3142"])}}</option>
                  <option value="M">{{(this.translation["3023"])}}</option>
                  <option value="F">{{(this.translation["3024"])}}</option>
                  <option value="X">{{(this.translation["3027"])}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3016"])}}*</label></div>
              <div class="col-lg-10"><input type="email" v-model="companyEmail" name="companyEmail" :placeholder='(this.translation["3016"])' class="form-control" required/>
              <div class="warning-feedback" v-if="checkExtension(companyEmail)">
                <span class="glyphicon glyphicon-warning-sign"></span> {{(this.translation["3145"])}}
              </div>
              </div>

            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3018"])}}</label></div>
              <div class="col-lg-6"><vue-tel-input v-model="companyMobileNumber" @validate="companyMobileVal" id="companyMobileNumber" ref="companyMobileNumber" name='companyMobileNumber' :autoFormat=true mode="international" :dropdownOptions ="{showDialCodeInList:true, showSearchBox:true, showFlags:true}" :preferredCountries=preferredCountries :defaultCountry="preferredLang" :validCharactersOnly=true :required=true></vue-tel-input></div>
              <label><small>{{(this.translation["3018a"])}}</small></label>
            </div>
          </div>
        </Transition>
        <!-- end Step 4 -->

        <!-- step 5 -->
        <Transition name="step">
          <div v-if="step === 5">
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3020"])}}*</label></div>
              <div class="col-lg-10">
                <select name="companyAirport" class="form-control" v-model="companyAirport" required>
                  <option value="" selected="selected">{{(this.translation["3142"])}}</option>
                  <option value="2040">Amsterdam (AMS)</option>
                  <option value="2535">Atlanta (ATL)</option>
                  <option value="2518">Barcelona (BCN)</option>
                  <option value="2536">Boston (BOS)</option>
                  <option value="2484">Brussels (BRU)</option>
                  <option value="2494">Cape Town (CPT)</option>
                  <option value="2509">Charles de Gaulle (CDG)</option>
                  <option value="2519">Chicago (ORD)</option>
                  <option value="2526">Cork (ORK)</option>
                  <option value="2515">Copenhagen (CPH)</option>
                  <option value="2526">Dublin (DUB)</option>
                  <option value="2494">Johannesburg (JNB)</option>
                  <option value="2500">Liege (LGG)</option>
                  <option value="2490">London Heathrow (LHR)</option>
                  <option value="2534">Los Angeles (LAX)</option>
                  <option value="2517">Madrid (MAD)</option>
                  <option value="2490">Manchester (MAN)</option>
                  <option value="8204">Miami (MIA)</option>
                  <option value="2516">Milan (MXP)</option>
                  <option value="2533">San Francisco (SFO)</option>
                  <option value="2532">Seattle (SEA)</option>
                  <option value="2526">Shannon (SNN)</option>
                  <option value="2511">Vienna (VIE)</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3019"])}}</label></div>
              <div class="col-lg-4">
                <select name="companyLanguage" class="form-control" v-model="companyLanguage">
                  <option value="en">English</option>
                  <option value="es">Espagnol</option>
                  <option value="ca">Català</option>
                  <option value="fr">Français</option>
                  <option value="nl">Nederlands</option>
                </select>
              </div>

              <div class="col-lg-2"><label>{{(this.translation["3021"])}}</label></div>
              <div class="col-lg-4">
                <select name="companyType" class="form-control" v-model="companyType">
                  <option value="" selected="selected">{{(this.translation["3142"])}}</option>
                  <option value="agent">{{(this.translation["3025"])}}</option>
                  <option value="haulier">{{(this.translation["3026"])}}</option>
                  <option value="other">{{(this.translation["3027"])}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-2"><label>{{(this.translation["3022"])}}</label></div>
              <div class="col-lg-10"><input v-model="companyRemarks" name="companyRemarks" :placeholder='(this.translation["3022"])' class="form-control" required/></div>
            </div>
          </div>
        </Transition>
        <!-- end step 5 -->
      </form>
      <div class="row">
        <div v-if="step !== 2" class="text-left col-sm-6"><button class="btn btn-info" type="button" @click="decrementStep">{{(this.translation["3139"])}}</button> </div>
        <div v-if="step === 2" class="text-left col-sm-6"></div>
        <div v-if="step !== 5" class="text-right col-sm-6"><button class="btn btn-info" type="button" @click="incrementStep" >{{(this.translation["3140"])}}</button> </div>
        <div v-if="step === 5" class="text-right col-sm-6"><button class="btn btn-info" type="button" @click="submit" >{{(this.translation["3141"])}}</button> </div>
      </div>
    </div>

  </div>
</template>

<style>
#firstTimeModal label {
  color: rgb(126.25, 126.25, 126.25);
  font-weight: 400 !important;
}
.step-enter {
  Opacity: 0;
}
.step-enter-active {
  transition : opacity 1s;
}
.step--leave {
  Opacity: 0;
}
.step-leave-active {
}

.alert-register {
  margin-top: -20px;
  margin-bottom: 20px;
}

.vue-tel-input {
  border-radius:2.5em;
}

.vti__input:not(.vti__search_box) {
  border-radius: 0 2.5em 2.5em 0;
}

.vti__dropdown {
  border-radius: 2.5em 0 0 2.5em;
}

.vti__dropdown-list {
  min-width:300px;
}

.vti__dropdown-item {
  padding: 3px 0px;
}

.vti__search_box {
  padding: 3px;
}

.needs-correction {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.warning-feedback {
  color: #4b4b4b;
  font-size: .875em;
  margin-top: .25rem;
  width: 100%;
  padding-left: 15px;
  margin-top: 5px;
};

</style>
<script>
import countriesTimezones from "./data/countriesTimezones.json";
import usStates from "./data/usStates.json";
import {VueTelInput} from 'vue-tel-input';

export default {
  name: 'Register',
  components: {
      VueTelInput
    },
  props:['trans'],
  data() {
    return{
      step: 2,
      companyName: '',
      companyAddress: '',
      companyZipcode: '',
      companyState: '',
      companyCountry: '',
      companyPhone: '',
      companyWebsite: '',
      companyGovRegNumber: '',
      companyVat: '',
      companyRaNumber: '',
      companyAdminName: '',
      companyAdminSurname: '',
      companyJobTitle: '',
      companyGender: '',
      companyEmail: '',
      companyMobileNumber: '',
      companyAirport: '',
      companyLanguage: '',
      companyType: '',
      companyRemarks: '',
      companyCity: '',
      timezone: '',
      errors: [],
      server_success: false,
      server_error: false,
      errorMessage: "",
      translation: [],
      showForm: true,
      loading: false,
      countriesTimezones: countriesTimezones,
      usStates: usStates,
      countryTimezones: '',
      preferredCountries: ['es','de','nl','at','be','fr','us','it','gb','ie','dk','ch'],
      preferredLang: 'US',
    }
  },
  created(){
    this.setTranslation()
    this.showForm = true
    this.loading = false
    this.companyLanguage = this.translation["code"]
    let _this = this
    $('#firstTimeModal').on('shown.bs.modal', function () {
      _this.cleanForm();
      _this.showForm = true
      _this.step = 1

    });

  },
  mounted() {
  },
  computed: {
    countriesTimezonesSorted: function() {
      return this.countriesTimezones.sort((t1,t2) => t1.name < t2.name ? -1 : 1);
    },
  },
  methods:{
    async submit() {
      if (!this.companyAirport && this.step == 5) {
        this.errors = true;
        $('select[name="companyAirport"]').addClass('needs-correction');
      } else {
        this.loading = true
        this.showForm = false;
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json",'X-Requested-With': 'XMLHttpRequest' },
          body: JSON.stringify({
            "_token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            form: {
              "companyName": this.companyName,
              "companyAddress": this.companyAddress,
              "companyZipcode": this.companyZipcode,
              "companyState": this.companyState,
              "companyCountry": this.companyCountry,
              "companyPhone": this.companyPhone,
              "companyWebsite": this.companyWebsite,
              "companyGovRegNumber": this.companyGovRegNumber,
              "companyVat": this.companyVat,
              "companyRaNumber": this.companyRaNumber,
              "companyAdminName": this.companyAdminName,
              "companyAdminSurname": this.companyAdminSurname,
              "companyJobTitle": this.companyJobTitle,
              "companyGender": this.companyGender,
              "companyEmail": this.companyEmail,
              "companyMobileNumber": this.companyMobileNumber,
              "companyAirport": this.companyAirport,
              "companyLanguage": this.companyLanguage,
              "companyType": this.companyType,
              "companyRemarks": this.companyRemarks,
              "companyCity": this.companyCity,
              "timezone": this.timezone,
            }
          })
        };
        const response = await fetch("/register_company", requestOptions)
        if( response.ok == false) {
          this.loading = false;
          this.server_error = true;
          this.errorMessage = response.statusText;
          this.server_success = false;
          this.showForm = true;
          return;
        };
        const result = await response.json();
        this.loading = false
        if( result.error == false) {
          this.server_error = false;
          this.errorMessage = "";
          this.server_success = true;
          this.cleanForm();
          this.showForm = false;
        } else {
          this.server_error = true;
          this.errorMessage = result.message;
          this.server_success = false;
          this.showForm = true;
        }
      };
    },
    countryChange(event, selectedIndex) {
        this.preferredLang = event.target.value;
        if(this.preferredLang != 'US') {
          this.companyState = "";
        }
        if(selectedIndex > 0) {
          if(!(this.preferredLang == 'US' && !this.companyState)) {
            this.countryTimezones = countriesTimezones[selectedIndex - 1].timezones;
            this.timezone = this.countryTimezones[0];
          }else {
            this.countryTimezones = [];
            this.timezone = null;
          }; 
        } else {
          this.countryTimezones = [];
          this.timezone = null;
        };
        if(this.companyPhone == '' && this.$refs.companyPhone) {
          this.$refs.companyPhone.choose(this.preferredLang);
        }
    },
    stateChange(event, selectedIndex) {
      if(selectedIndex > 0) {
        this.countryTimezones = usStates[selectedIndex - 1].ianaTimezones;
        this.timezone = this.countryTimezones[0];
      } else {
        this.countryTimezones = [];
        this.timezone = null;
      }
    },
    companyPhoneVal: function(object) {
      if (object.valid === true) {
        this.companyPhoneValue = true;
      } else {
        this.companyPhoneValue = false;
      }
    },
    companyMobileVal: function(object) {
      if (object.valid === true) {
        this.companyMobileValue = true;
      } else {
        this.companyMobileValue = false;
      }
    },
    setTranslation(){
      this.translation = this.trans;
    },
    cleanForm() {
      this.companyName = "";
      this.companyAddress = "";
      this.companyZipcode = "";
      this.companyState = "";
      this.companyCountry = "";
      this.companyPhone = "";
      this.companyPhoneValue ="false";
      this.companyWebsite = "";
      this.companyGovRegNumber = "";
      this.companyVat = "";
      this.companyRaNumber = "";
      this.companyAdminName = "";
      this.companyAdminSurname = "";
      this.companyJobTitle = "";
      this.companyGender = "";
      this.companyEmail = "";
      this.companyMobileNumber = "";
      this.companyMobileValue ="false";
      this.companyAirport = "";
      this.companyLanguage = "";
      this.companyType = "";
      this.companyRemarks = "";
      this.companyCity = "";
      this.timezone = "";
    },
    setErrors: function () {
      this.errors = false;
      $('.needs-correction').removeClass('needs-correction');

      if (!this.companyName && this.step == 2) {
        this.errors = true;
        $('input[name="companyName"]').addClass('needs-correction');
      }
      if (!this.companyAddress && this.step == 2) {
        this.errors = true;
        $('input[name="companyAddress"]').addClass('needs-correction');
      }
      if (!this.companyZipcode && this.step == 2) {
        this.errors = true;
        $('input[name="companyZipcode"]').addClass('needs-correction');
      }
      if (!this.companyCity && this.step == 2) {
        this.errors = true;
        $('input[name="companyCity"]').addClass('needs-correction');
      }
      if (!this.companyCountry && this.step == 2) {
        this.errors = true;
        $('select[name="companyCountry"]').addClass('needs-correction');
      }
      if (!this.companyState && this.companyCountry == "US" && this.step == 2) {
        this.errors = true;
        $('select[name="companyState"]').addClass('needs-correction');
      }
      if (!this.companyPhoneValue && this.step == 2) {
        this.errors = true;
        $('#companyPhone').addClass('needs-correction');
      }
      if (this.companyGovRegNumber.length < 6 && this.step == 3) {
        this.errors = true;
        $('input[name="companyGovRegNumber"]').addClass('needs-correction');
      }
      if (this.companyVat.length < 6 && this.step == 3) {
        this.errors = true;
        $('input[name="companyVat"]').addClass('needs-correction');
      }
      if (!this.companyAdminName && this.step == 4) {
        this.errors = true;
        $('input[name="companyAdminName"]').addClass('needs-correction');
      }
      if (!this.companyAdminSurname && this.step == 4) {
        this.errors = true;
        $('input[name="companyAdminSurname"]').addClass('needs-correction');
      }
      if (!this.companyJobTitle && this.step == 4) {
        this.errors = true;
        $('input[name="companyJobTitle"]').addClass('needs-correction');
      }
      if (!this.validateEmail(this.companyEmail) && this.step == 4) {
        this.errors = true;
        $('input[name="companyEmail"]').addClass('needs-correction');
      }
      if (!this.companyMobileValue && this.step == 4) {
        this.errors = true;
        $('#companyMobileNumber').addClass('needs-correction');
      }
    },
    handleIncrement: function () {
      this.errors = false;
      let self = this
      switch (this.step) {
        case 1:
          if (this.companyName) {
            this.step = this.step + 1;
          } else {
            this.setErrors();
          }
          break;
        case 2:
          if(this.companyCountry == 'US' && !this.companyState) {
            var stateFilled = false;
          } else {
            var stateFilled = true;
          }
          if (this.companyAddress && this.companyZipcode && this.companyCity && this.companyCountry && stateFilled && this.companyPhoneValue && this.companyName) {
            this.step = this.step + 1;
          } else {
            this.setErrors();
          }
          break;
        case 3:
          if (this.companyGovRegNumber.length > 5 && this.companyVat.length > 5) {
            this.step = this.step + 1;
          } else {
            this.setErrors();
          }
          break;
        case 4:
          if (this.companyAdminName && this.companyAdminSurname && this.companyJobTitle && this.validateEmail(this.companyEmail) && this.companyMobileValue) {
            this.step = this.step + 1;
          } else {
            this.setErrors();
          }
          break;
        case 5:
          if(this.companyAirport) {
            this.step = this.step + 1;
          } else {
            this.setErrors();
          }
          break;
      }
    },
    incrementStep() {
      this.handleIncrement();
    },
    handleDecrement: function () {
      this.errors = false;
      this.step = this.step - 1;
    
    },
    decrementStep() {
      this.handleDecrement();
    },
    validateEmail(mail) {
    var validMail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (mail.match(validMail)) {
        return true;
    } else {
        return false;
    }
    },
    checkExtension(mail) {
        return ["gmail", "yahoo", "hotmail", "outlook", "wanadoo", "protonmail", "msn.com", "live.com", "aol.com", "icloud.com", "zoho.com", "gmx.com", "yandex.com", "mail.com"].some(provider => mail.toLowerCase().includes(provider));
    }
  }
}
</script>

